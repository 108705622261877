import React, { useState } from 'react'
import Signup from './register.js'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
// import './App.css'
import logo from '../logo.svg'
import cover from '../cover.jpg'
import axios from 'axios'

function Stores() {
  let match = useRouteMatch()
  const [signedUp, setSignedUp] = useState(false)
  const handleSignup = (storeData) => {
    axios
      .post('/api/stores/add.php', storeData)
      .then((response) => {
        console.log(response.data)
        setSignedUp(true)
        if (response.data.result === 'success') {
          // history.push(`${url}/confirmation`)
        }
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className="Stores">
      <header>
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" width="210" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon bi bi-list text-white"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/stores"
                  >
                    Händlerportal
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#register">
                    Registrierung
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Zur Website
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <Switch>
        <Route path={match.path}>
          <div className="container">
            <section>
              <div className="row">
                <div className="col">
                  <h2 className="mt-4 mb-3">Wir möchten Sie unterstützen.</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-9 col-md-7 col-xl-6">
                  {signedUp ? (
                    <div>
                      <p>
                        Vielen Dank! Wir haben Sie in die Liste der
                        Buchhandlungen eingetragen.
                      </p>
                      <p>
                        Diese finden Sie hier: <Link to="/">Zur Website</Link>.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        Liebe Buchhändler, wir freuen uns unglaublich, dass das
                        erste Buch der neuen Carlsen-Serie &bdquo;Das
                        Vermächtnis des Wunderlandes&ldquo; am 26. Mai
                        erscheint.
                      </p>
                      <p>
                        Der Lockdown hat sicherlich auch Sie hart getroffen,
                        während die Onlinegiganten Rekordumsätze feiern konnten.
                        Deshalb würden wir die potenziellen Käufer des Buchs
                        gerne aufrufen, dieses Buch nicht direkt bei Amazon zu
                        bestellen, sondern sich an ihre lokalen Buchhändler zu
                        wenden. Aus dem gleichen Grund werden auch wir das Buch
                        vorerst nicht in unseren Online-Shop aufnehmen, da wir
                        bislang vergleichsweise glimpflich durch die Krise
                        gekommen sind.
                      </p>
                      <p>
                        Dafür wollen wir auf der Startseite dieser Website auch
                        direkt ein Liste von Buchhandlungen, bzw. vielleicht
                        auch eine Karte davon, anbieten, um diese den Käufern
                        ein paar Klicks näher zu bringen. Falls wir Sie in diese
                        Liste aufnehmen sollen, füllen Sie einfach das Formular
                        unten aus.
                      </p>
                      <p>
                        Natürlich ist es nur ein kleiner Tropfen auf einem sehr
                        heißen Stein, aber wir hoffen sehr, Ihnen dadurch einige
                        Bestellungen und hoffentlich auch Neukunden bescheren zu
                        können.
                      </p>
                      <p>Das Team vom Miniatur Wunderland</p>
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-3 col-md-4 offset-md-1 col-lg-4 offset-lg-1 col-xl-3 offset-xl-2">
                  <img src={cover} alt="Cover" className="img-fluid" />
                </div>
              </div>
            </section>
            {signedUp ? null : (
              <section>
                <div id="register" className="row">
                  <div className="col">
                    <Signup onSubmit={handleSignup} />
                  </div>
                </div>
              </section>
            )}
          </div>
        </Route>
        <Route path={`${match.path}/register`}>Register</Route>
      </Switch>
    </div>
  )
}

export default Stores
