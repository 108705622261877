import React from 'react'
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import Stores from './stores/index.js'
import Site from './site/index.js'

function App() {
  let location = useLocation()
  return (
    <div className="App">
      <Switch>
        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
        <Route exact path="/">
          <div className="container">
            <Site />
          </div>
        </Route>
        <Route path="/stores">
          <Stores />
        </Route>
      </Switch>
    </div>
  )
}

export default App
