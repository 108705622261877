import React, { useState, useEffect } from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
// import './App.css'
import logo from '../logo.svg'
import cover from '../cover.jpg'
import rom from '../rom.jpg'
import axios from 'axios'
import './leaflet.css'
import StoreList from './storelist.js'

function Site() {
  let match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [storesLoaded, setStoresLoaded] = useState(false)

  const getStores = () => {
    axios
      .get('/storedata/stores.json')
      .then((response) => {
        setStores(response.data)
        setStoresLoaded(true)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => getStores(), [])

  return (
    <div className="site">
      <header>
        <div className="container">
          <nav
            id="navbar-site"
            className="navbar my-0 py-0 fixed-top navbar-dark navbar-expand-md"
            style={{ backgroundColor: '#7c1d1d' }}
          >
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="Logo" width="210" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon bi bi-list text-white"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav navbar-nav">
                  <li className="nav-item ">
                    <a className="nav-link" href="#buch">
                      Das Buch
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#lesung">
                      Dabei sein
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#kaufen">
                      Hier kaufen
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="row" style={{ display: 'none' }}>
            <div className="col py-2 text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                style={{ width: '80%', maxWidth: '400px' }}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </header>
      <Switch>
        <Route path={match.path}>
          <div className="container">
            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-site"
              data-bs-offset="0"
              tabIndex="0"
            >
              <section id="buch">
                <div style={{ paddingTop: '80px' }}>
                  <div className="row">
                    <div className="col">
                      <h1 className="display-5 mt-5 pt-3 mb-4">
                        Mitreißend, fesselnd, lustig!
                      </h1>
                      <h4 className="mb-5">
                        Eine rasante Achterbahnfahrt mitten hinein in die
                        fantastische Welt des Wunderlands
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <ul className="mg">
                        <li>
                          Bahn frei für einen tollen Mix aus Freundschaft,
                          Spannung und Abenteuer - packendes Lesefutter für
                          Kinder ab 8 Jahren!
                        </li>
                        <li>
                          DAS Kinderbuch für Fans des Miniatur Wunderlands!
                          Dieses Abenteuer spielt in der einzigen fiktiven Welt
                          der beliebten Attraktion: Knuffingen.
                        </li>
                        <li>
                          Ein Buch wird Wirklichkeit: Szenen aus dem ersten Band
                          und die Charaktere dieser Buchreihe finden sich schon
                          im Miniatur Wunderland wieder und können dort bestaunt
                          werden.
                        </li>
                        <li>
                          Ab Band 2 werden auch andere, reale Schauplätze aus
                          dem Miniatur Wunderland besucht. Die Reihe ist eine
                          spektakuläre Reise durch die große kleine Welt, die
                          jährlich Millionen Besucher begeistert.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="row">
                  <div className="col">
                    <h2 className="mt-5 pt-1 mb-4">Die Geschichte</h2>
                  </div>
                </div>
                <div className="row  justify-content-around">
                  <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 pr-4 pr-md-0">
                    <img src={cover} alt="Cover" className="img-fluid" />
                  </div>
                  <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
                    <p>
                      Das gibt es doch nicht! Als die Geschwister Olivia, Bruno
                      und Tom zusammen mit ihrer Freundin Pi die alte und längst
                      verschollen geglaubte Modelleisenbahn des Großvaters
                      wiederfinden, sind sie natürlich erst einmal begeistert.
                      Doch dann passieren mit einem Mal ganz seltsame Sachen um
                      sie herum – und das ausgerechnet kurz vor der wichtigsten
                      Zugmesse, die spektakuläre Züge aus aller Welt in den
                      kleinen Ort Knuffingen bringen soll. Gibt es da etwa einen
                      Zusammenhang? Und welche Rolle spielt der von der Messe
                      keineswegs begeisterte Biernatzki? Klar, dass die vier den
                      mysteriösen Geschehnissen auf den Grund gehen müssen. Doch
                      dann passiert etwas, mit dem keiner von ihnen gerechnet
                      hat! Und damit beginnt das aufregendste Abenteuer ihres
                      Lebens!
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <div style={{ paddingTop: '80px' }} id="lesung" className="row">
                  <div className="col">
                    <h1 className="mt-4 mb-3">Wir verlosen 10 Plätze!</h1>
                  </div>
                </div>
                <div className="row  justify-content-start">
                  <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
                    <h4 className="mb-4">
                      Autorenlesung im Miniatur Wunderland
                    </h4>
                    <p>
                      Am 30. Mai wird im Miniatur Wunderland eine Autorenlesung
                      stattfinden, die auch live ins Internet übertragen wird.
                      10 Kinder mit Familien werden die Möglichkeit bekommen,
                      selbst vor Ort dabei zu sein.
                    </p>
                    <p>
                      Die Plätze werden verlost. Um teilzunehmen, schreiben Sie
                      uns eine kurze E-Mail, warum Sie unbedingt dabei sein
                      möchten, an{' '}
                      <a href="mailto:l.dockweiler@miniatur-wunderland.de">
                        l.dockweiler@miniatur-wunderland.de
                      </a>
                      . Bitte geben Sie auch an, mit wie vielen Personen Sie
                      voraussichtlich kommen würden.
                    </p>
                    <p>
                      <small>
                        Selbstverständlich haben wir ein Hygiene-Konzept für
                        diese Veranstaltung und falls Sie Fragen hierzu haben,
                        schreiben Sie uns gerne!
                      </small>
                    </p>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 pr-4 pr-md-0">
                    <img src={rom} alt="Cover" className="img-fluid" />
                  </div>
                </div>
              </section>
              <section>
                <div id="kaufen" className="row mt-5">
                  <div className="col">
                    <h2 className="mt-4 mb-3">
                      Lassen Sie uns dem Buchhandel helfen.
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      Wie der gesamte Einzelhandel haben auch die Buchläden sehr
                      unter dem Lockdown gelitten. Wir würden uns deshalb sehr
                      freuen, wenn Sie das Buch nicht bei den bekannten
                      Online-Giganten bestellen, sondern beim Buchladen Ihres
                      Vertrauens. Viele davon haben eigene Online-Shops oder
                      nehmen telefonisch Bestellungen an.
                    </p>
                    <p>
                      Unten finden Sie einige davon - vielleicht gibt es ja auch
                      einen in Ihrer Nähe:
                    </p>
                    {storesLoaded ? (
                      <StoreList stores={stores} />
                    ) : (
                      <p>Lädt...</p>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  )
}

export default Site
